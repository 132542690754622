<template>
  <div>
    <VTitle :title="$t('app.logs', 2)" class="section-title" />

    <div class="grid grid-cols-5 gap-x-8 mt-10">
      <TableCard
        class="col-span-4"
        :rows="displayedRows"
        :headers="headers"
        :pagination="pagination"
        :is-loading="isLoading"
        display-pagination
        @update:current_page="onUpdateCurrentPage"
      >
        <template #title>
          {{ $t("app.logs") }}
        </template>

        <template #title-actions>
          <VButton
            icon="fa-file-download fa-lg"
            class="btn-primary"
            @click="onClickDownload"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template #item.action="{ item }">
          <VChip
            :text="item.displayed_action"
            :class="getActionColor(item.action)"
          />
        </template>
      </TableCard>

      <div>
        <VCard>
          <template #title>
            {{ $t("app.log_types") }}
          </template>

          <template #content>
            <div class="chat">
              <div
                class="chat__tabs nav grid grid-cols-1 nav-tabs justify-center"
                role="tablist"
              >
                <VTab
                  :selected-tab="currentTab"
                  :label="$t('app.logins', 2)"
                  name="logins"
                  icon="fal fa-sign-in-alt mx-4"
                  class="flex-1 py-2 rounded-md w-full"
                  lowercase-label
                  @click="onClickUpdateTab"
                />

                <VTab
                  :selected-tab="currentTab"
                  :label="$t('app.actions')"
                  name="actions"
                  icon="fal fa-pencil-ruler mx-4"
                  class="flex-1 py-2 rounded-md w-full"
                  lowercase-label
                  @click="onClickUpdateTab"
                />
              </div>
            </div>
          </template>
        </VCard>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, inject, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
// Composables
import useReadMultiple from "@/composables/useReadMultiple";
import usePaginate from "@/composables/usePaginate";
import useColor from "@/composables/useColor";
import useDownload from "@/composables/useDownload";
import useTabs from "@/composables/useTabs";
// Components
import TableCard from "@/components/tables/TableCard";
import VCard from "@/components/VCard";
import VTitle from "@/components/VTitle";
import VTab from "@/components/VTab";
import VChip from "@/components/VChip";
import VButton from "@/components/VButton";

export default {
  components: {
    VTitle,
    TableCard,
    VCard,
    VTab,
    VChip,
    VButton
  },
  props: {
    id: {
      type: [String, Number],
      default: ""
    },
    user: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // MISC
    const { t } = useI18n();
    const store = useStore();
    const moment = inject("moment");

    const { pagination, data, isLoading, read } = useReadMultiple();
    const { currentTab, updateTab } = useTabs("logins");

    // COMPUTED
    const documentTitle = computed(() => {
      const title = `${t("app.logs")} - ${t("app.users", 2)} - ${t(
        "app.administration"
      )}`;

      if (props.user?.firstname && props.user?.lastname) {
        return `${props.user.firstname} ${props.user.lastname} - ${title}`;
      }

      return title;
    });
    const headers = computed(() => {
      let headers = [
        {
          text: t("app.date"),
          value: "date"
        },
        {
          text: t("app.time"),
          value: "time"
        },
        {
          text: t("app.ip_address"),
          value: "ip"
        }
      ];

      if (currentTab.value === "actions") {
        headers = [
          ...headers,
          {
            text: t("app.resource_name"),
            value: "resource_name"
          }
        ];
      }

      return [
        ...headers,
        {
          text: t("app.action"),
          value: "action"
        }
      ];
    });
    const dateFormat = computed(() => store.getters["app/date_format"]);
    const displayedRows = computed(() => {
      return data.value.map(row => ({
        ...row,
        date: moment(row.logged_at).format(dateFormat.value),
        time: moment(row.logged_at).format("HH:mm:ss"),
        displayed_action: t(`backend.${row.action}`)
      }));
    });

    // METHODS
    const getData = async () => {
      await read({
        endpoint: `administration.logs.${currentTab.value}`,
        pathParams: {
          id: props.id
        }
      });
    };

    const { download } = useDownload();

    const onClickDownload = () => {
      download(`administration.logs.${currentTab.value}`, { id: props.id });
    };

    const { onUpdateCurrentPage } = usePaginate(pagination, getData);
    const { getActionColor } = useColor();

    // LIFECYCLE HOOKS
    onMounted(async () => {
      await getData();
    });

    // WATCH
    watch(currentTab, getData);

    return {
      documentTitle,
      headers,
      displayedRows,
      onClickDownload,
      // useTabs
      currentTab,
      onClickUpdateTab: updateTab,
      // useReadMultiple
      pagination,
      isLoading,
      // usePaginate
      onUpdateCurrentPage,
      // useColor
      getActionColor
    };
  }
};
</script>
